.aa-Autocomplete { position: relative; }

.aa-Form {
  display: flex;
  flex-direction: column;

  #input-container { position: relative; }
  img { position: absolute; top: calc(50% - 9px); margin-left: 14px; }
  input {
    width: 100%;
    height: 50px;
    background-color: #fff;
    border: 1px solid var(--gray);
    border-radius: 10px;
    box-shadow: inset 0 0 0 1px transparent;
    padding-left: 46px;
    -webkit-appearance: none;
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 14px;
      width: 14px;
      margin: 0 14px;
      align-self: center;
      background-image: url("../assets/close-icon.svg");
      cursor: pointer;
    }
    &:hover { border-color: #000; }
    &:focus-visible { 
      outline: none; 
      border-color: blue; 
      box-shadow: inset 0 0 0 1px blue;
    }
    &[aria-controls="autocomplete-list"] { border-radius: 10px 10px 0 0;  }
  }
}

.aa-Panel {
  border-radius: 0 0 10px 10px;
  position: absolute;
  width: calc(100% - 2px);
  
  &:has(a) {
    border: 1px solid var(--light-gray);
    border-top: none;
    background-color: #fff;
    box-shadow: 0 2px 2px rgb(0 0 0 / 10%);
  }
}

@media (max-width: 550px) {
  .aa-Form img { 
    width: 16px; 
    height: 16px;
    top: calc(50% - 8px); 
    margin-left: 12px; 
  }
  .aa-Form input { padding-left: 38px; }
  .aa-Form input::-webkit-search-cancel-button { margin: 0 12px 0 2px; }
}