.SubstituteItem {
  border-bottom: 1px solid var(--light-gray);
  padding: 16px 0;
}
.name span { font-weight: normal; }
.proportion { font-style: italic; }
.SubstituteItem:has(.proportion, .note) {
  .name { margin-bottom: 6px; }
}
// if there is both a proportion and a note
.SubstituteItem:has(.proportion):has(.note) {
  .proportion { margin-bottom: 6px; }
}