.SubstituteCategory { margin-bottom: 36px; }
.SubstituteCategory:last-child { margin-bottom: 0; }
// if there is only one SubstituteCategory
.SubstituteCategory:only-of-type {
  border-top: 1px solid var(--light-gray);
  h3 { display: none; }
}
.SubstituteCategory h3 {
  display: inline-block;
  border-radius: 13px;
  padding: 4px 10px;
  border: 1px solid black;
}
