:root {
  --dark-gray: #595959;
  --gray: #969696;
  --light-gray: #ccc;
}

// to fix the page jump between no scrollbar & scrollbar
html { overflow-y: scroll; }
html, body { margin: 0; -webkit-font-smoothing: antialiased; }
main { 
  max-width: 600px; 
  margin: 0 auto; 
  padding: 100px 20px 75px; 
}
h1, h2, h3 { 
  margin: 0; 
  font-weight: normal; 
  font-family: sans-serif; 
  color: var(--dark-gray); 
}
h1 { font-size: 28px; font-family: serif; }
h2 { font-size: 18px; }
h3 { font-size: 14px; color: #000;}
p { margin: 0; }
ul, li { margin: 0; padding: 0; list-style-type: none; }
a { text-decoration: none; }
.font-ingredient {
  font-size: 30px;
  font-family: serif;
  color: #000;
}
.font-substitute {
  font-size: 20px;
  font-weight: bold;
  font-family: sans-serif;
  color: #000;
}
.font-note {
  font-size: 16px;
  font-family: sans-serif;
  color: var(--dark-gray);
  line-height: 140%;
}

.panel {
  margin-top: 25px;
  padding: 30px 44px 40px;
  background: #f9f9f9;
  border-radius: 10px;

  h2 { margin-bottom: 20px; }
}

@media (max-width: 800px) {
  main { padding: 16px 20px 75px;  }
  h1 { font-size: 20px; }
  h2 { font-size: 16px; }
  .font-ingredient { font-size: 18px; font-family: sans-serif; }
  .panel { margin-top: 16px; padding: 26px 30px 30px; }
  .panel h2 { margin-bottom: 16px; }
}